import React, { useState, createContext } from "react";

export const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const [isTranslated, setIsTranslated] = useState(false);

  return (
    <TranslationContext.Provider value={{ isTranslated, setTranslated: setIsTranslated }}>
      {children}
    </TranslationContext.Provider>
  );
};
