import React from "react";
import ReactDOM from "react-dom/client";

import "./i18n";

import App from "./App";

import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { getConfig } from "./config_auth";
import * as serviceWorker from "./serviceWorker";
import history from "./utils/history";
import { AuthProvider } from "./utils/AuthContext";
import { TranslationProvider } from "./utils/TranslationContext";
import { Auth0Provider } from "@auth0/auth0-react";

const onRedirectCallback = (appState: Record<string, any>) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <Provider store={configureStore({})}>
    <React.Fragment>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Auth0Provider {...providerConfig}>
          <AuthProvider>
            <TranslationProvider>
              <App />
            </TranslationProvider>
          </AuthProvider>
        </Auth0Provider>,
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);
